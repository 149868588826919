import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决重复点击相同路由报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  /** pc端路由 */
  // 首页
  {
    path: '/',
    name: 'PcIndex',
    component: () => import('../views/pc/index.vue')
  },
  // 产品功能页
  {
    path: '/product',
    name: 'PcProduct',
    component: () => import('../views/pc/product.vue')
  },
  // 适用场景页
  {
    path: '/sence',
    name: 'PcSence',
    component: () => import('../views/pc/sence.vue')
  },
  // 智能硬件页
  {
    path: '/intelligent',
    name: 'PcIntelligent',
    component: () => import('../views/pc/intelligent.vue')
  },
  // 服务版本页
  {
    path: '/server',
    name: 'PcServer',
    component: () => import('../views/pc/server.vue')
  },
  // 关于我们页
  {
    path: '/about',
    name: 'PcAbout',
    component: () => import('../views/pc/about.vue')
  },
  /** 移动端路由 */
  // 首页
  {
    path: '/mb_index',
    name: 'MbIndex',
    component: () => import('../views/mb/index.vue')
  },
  // 产品功能
  {
    path: '/mb_product',
    name: 'MbProduct',
    component: () => import('../views/mb/product.vue')
  },
  // 适用场景页
  {
    path: '/mb_sence',
    name: 'MbSence',
    component: () => import('../views/mb/sence.vue')
  },
  // 智能硬件
  {
    path: '/mb_intelligent',
    name: 'MbIntelligent',
    component: () => import('../views/mb/intelligent.vue')
  },
  // 服务版本
  {
    path: '/mb_server',
    name: 'MbServer',
    component: () => import('../views/mb/server.vue')
  },
  // 关于我们
  {
    path: '/mb_about',
    name: 'MbAbout',
    component: () => import('../views/mb/about.vue')
  },
  // 注册
  {
    path: '/mb_register',
    name: 'MbRegister',
    component: () => import('../views/mb/register.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 切换页面时回到顶部
router.beforeEach((to, from, next) => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth"
  })
  next()
})

export default router
