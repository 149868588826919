import request from "./request"

// 获取服务版本信息
export function serviceVersionApi() {
  return request({
    url: 'system/tenant-package/service-version-official',
    method: 'GET'
  })
}

// 获取注册验证码
export function registerSendSmsCode(mobile, scene) {
  return request({
    url: 'system/auth/register-send-sms-code',
    method: 'POST',
    data: {
      mobile,
      scene
    }
  })
}

// 注册第一步
export function registerFirstStep(mobile, code) {
  return request({
    url: 'system/auth/register-first',
    method: 'POST',
    data: {
      mobile,
      code
    }
  })
}

// 注册第二步
export function registerSecondStep(data) {
  return request({
    url: 'system/auth/register-second',
    method: 'POST',
    data: data
  })
}

// 查询生效中优惠活动
export function activity() {
  return request({
    url: 'butler/activity/efficacious-activity',
    method: 'GET'
  })
}
