import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/rem'
import './assets/common.scss'
import './assets/font/font.scss'
import { IMG_URL } from './utils/config'
//引入图片懒加载插件
import VueLazyLoad from 'vue-lazyload'
import 'animate.css';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

Vue.config.productionTip = false

Vue.prototype.ALL_IMG_URL = IMG_URL
Vue.prototype.IMG_URL = IMG_URL + 'pc/'
Vue.prototype.MB_IMG_URL = IMG_URL + 'mb/'

Vue.use(VueAwesomeSwiper)
Vue.use(VueLazyLoad)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
