<template>
  <div class="register">
    <!-- <div class="customer" @click="handleClick">
      <img :src="MB_IMG_URL + 'add/customer.png'" alt="">
      在线客服咨询
    </div> -->
    <div class="try" @click="tryClick">免费使用</div>
  </div>
</template>

<script>
export default {
  methods: {
    // 53客服
    // handleClick() {
    //   _czc.push(["_trackEvent", '咨询按钮', '在线客服咨询']);
    //   var _53code = document.createElement("script");
    //   _53code.src = "https://tb.53kf.com/code/code/e7947fd5e4009e40547964bebdb69f7b5/1";
    //   _53code.setAttribute("async", true);
    //   _53code.addEventListener("load", function () { });
    //   var s = document.getElementsByTagName("script")[0];
    //   s.parentNode.insertBefore(_53code, s);
    // },
    // 免费使用
    tryClick() {
      _czc.push(["_trackEvent", "试用按钮", "免费使用"]);
      this.$router.push("/mb_register");
    },
  },
};
</script>

<style lang="scss" scoped>
.register {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 19.67rem;
  background: #fff;
  box-shadow: 0 -0.33rem 2.67rem 0 #b9b9b940;
  padding: 0 6.67rem;
  box-sizing: border-box;
  z-index: 9;

  // .customer {
  //   display: flex;
  //   align-items: center;
  //   color: #6d7582;
  //   font-size: 4rem;
  //   font-weight: 400;

  //   img {
  //     width: 9rem;
  //     height: 9rem;
  //     margin-right: 2.5rem;
  //   }
  // }

  .try {
    width: 100%;
    height: 14.33rem;
    color: #ffffff;
    font-size: 5.33rem;
    font-weight: 500;
    border-radius: 1.33rem;
    background: #286bff;
    text-align: center;
    line-height: 14.33rem;
  }
}
</style>