<template>
  <div class="header">
    <img class="logo" v-lazy="MB_IMG_URL + 'add/logo1.png'" alt="" />
    <span
      class="header-tag"
      v-if="
        $store.state.activity &&
        ($store.state.activity.serverTag || $store.state.activity.deviceTag)
      "
    >
      特惠
    </span>
    <img
      @click="maskFlag = true"
      class="choose"
      v-lazy="MB_IMG_URL + 'choose.png'"
      alt=""
    />
    <div class="mask" v-show="maskFlag">
      <ul class="animate__animated animate__fadeInRight">
        <li
          @click="handleClick(item.id)"
          v-for="item in navBarList"
          :key="item.id"
        >
          <img v-lazy="MB_IMG_URL + item.icon" alt="" />
          {{ item.label }}
          <span
            class="tag"
            v-if="
              ($store.state.activity &&
                item.id === 3 &&
                $store.state.activity.serverTag) ||
              ($store.state.activity &&
                item.id === 2 &&
                $store.state.activity.deviceTag)
            "
            >特惠</span
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navBarList: [
        { id: 0, label: "首页", icon: "icon1.png" },
        { id: 1, label: "产品功能", icon: "icon2.png" },
        { id: 5, label: "适用场景", icon: "icon6.png" },
        { id: 2, label: "智能硬件", icon: "icon3.png" },
        { id: 3, label: "版本服务", icon: "icon4.png" },
        { id: 4, label: "关于我们", icon: "icon5.png" },
      ],
      maskFlag: false,
    };
  },
  methods: {
    handleClick(id) {
      this.maskFlag = false;
      switch (id) {
        case 0:
          _czc.push(["_trackEvent", "查看首页", "首页"]);
          return this.$router.push("/mb_index");
        case 1:
          _czc.push(["_trackEvent", "查看功能", "产品功能"]);
          return this.$router.push("/mb_product");
        case 2:
          _czc.push(["_trackEvent", "查看硬件", "智能硬件"]);
          return this.$router.push("/mb_intelligent");
        case 3:
          _czc.push(["_trackEvent", "查看版本", "版本服务"]);
          return this.$router.push("/mb_server");
        case 4:
          _czc.push(["_trackEvent", "查看公司介绍", "关于我们"]);
          return this.$router.push("/mb_about");
        case 5:
          _czc.push(["_trackEvent", "查看适用场景", "适用场景"]);
          return this.$router.push("/mb_sence");
        default:
          return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 20.17rem;
  background: #fff;
  padding: 0 6.83rem;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 111;

  &-tag {
    width: 20rem;
    height: 6rem;
    line-height: 6rem;
    text-align: center;
    color: #ffffff;
    font-size: 4rem;
    background: linear-gradient(
      90deg,
      #ffa500 0%,
      #ffa33f 48.13%,
      #ff6b00 104.63%
    );
    position: absolute;
    right: 18rem;
    top: 7.08rem;
  }

  &-tag:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: 1rem;
    right: -2rem;
    border-top: solid 2rem transparent;
    border-left: solid 2rem #ff6b00;
    border-bottom: solid 2rem transparent;
  }

  .logo {
    width: 22.67rem;
    height: 7.17rem;
  }

  .choose {
    width: 9.17rem;
    height: 8.17rem;
  }

  .mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #00000069;

    ul {
      background: #fff;
      position: absolute;
      top: 20.17rem;
      right: 3.5rem;
      width: 56.33rem;
      border-radius: 2rem;
      background: #fff;
      box-shadow: 0 0 2rem 0.5rem #ffffff40;
      color: #282828;
      font-size: 5.33rem;
      font-weight: 400;
      padding: 0 4.17rem;
      box-sizing: border-box;

      li {
        text-align: left;
        padding: 5.33rem 5.67rem;
        box-sizing: border-box;
        border-bottom: solid 0.17rem #dddddd;
        position: relative;

        img {
          width: 7rem;
          height: 7rem;
        }

        .tag {
          width: 20rem;
          height: 6rem;
          line-height: 6rem;
          text-align: center;
          color: #ffffff;
          font-size: 4rem;
          border-radius: 4rem 0;
          background: linear-gradient(
            90deg,
            #ffa500 0%,
            #ffa33f 48.13%,
            #ff6b00 104.63%
          );
          position: absolute;
          transform: scale(0.7);
          right: -4rem;
          top: 3.5rem;
        }
      }

      li:last-child {
        border-bottom: none;
      }
    }

    ul::before {
      content: "";
      width: 0;
      height: 0;
      border-left: 2.5rem solid transparent;
      border-right: 2.5rem solid transparent;
      border-bottom: 2.5rem solid #fff;
      position: absolute;
      top: -2.5rem;
      right: 5rem;
    }
  }
}
</style>