<template>
  <div class="container" id="floatDiv">
    <img
      @mouseover="wechatOver"
      @mouseleave="wechatLeave"
      class="wechat"
      v-lazy="IMG_URL + wechat"
      alt=""
    />
    <!-- <img @click="handleClick" class="customer" v-lazy="IMG_URL + customer" alt=""> -->
    <div class="to-top" v-if="toTop" @click="backTop">
      <img v-lazy="IMG_URL + 'index/top.png'" alt="" />
      置顶
    </div>
    <div
      class="bubble"
      id="bubble"
      :style="{ backgroundImage: 'url(' + IMG_URL + 'bubble.png)' }"
    >
      <img :src="ALL_IMG_URL + 'Customerservice-QRcode.png'" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      wechat: "index/wechat-active.png",
      customer: "index/customer-active.png",
      // 滚动条参数
      oldScrollTop: 0,
      scrollTop: 0,
      // 控制是否展示回到顶部
      toTop: false,
    };
  },
  watch: {
    scrollTop(newValue, oldValue) {
      if (newValue >= 1000) {
        this.toTop = true;
      } else {
        this.toTop = false;
      }
      const element = document.getElementById("floatDiv");
      setTimeout(() => {
        if (newValue == window.scrollY) {
          this.oldScrollTop = newValue;
          element.style.right = "0";
          this.wechat = "index/wechat-active.png";
          this.customer = "index/customer-active.png";
        }
      }, 200);
      if (this.oldScrollTop == oldValue) {
        element.style.right = "-1.56rem";
        this.wechat = "index/wechat.png";
        this.customer = "index/customer.png";
      }
    },
  },
  // created() {
  //   var _53code = document.createElement("script");
  //   _53code.src =
  //     "https://tb.53kf.com/code/code/e7947fd5e4009e40547964bebdb69f7b5/1";
  //   _53code.setAttribute("async", true); // 可选，异步加载脚本
  //   _53code.addEventListener("load", function () {});
  //   var s = document.getElementsByTagName("script")[0];
  //   s.parentNode.insertBefore(_53code, s);
  // },
  mounted() {
    this.handleScroll();
  },
  methods: {
    // 浏览器滚动
    handleScroll() {
      window.addEventListener("scroll", () => {
        this.scrollTop = window.scrollY;
      });
    },
    // 回到顶部
    backTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    // 微信鼠标悬浮
    wechatOver() {
      const element = document.getElementById("bubble");
      element.style.display = "block";
    },
    // 微信鼠标移开
    wechatLeave() {
      const element = document.getElementById("bubble");
      element.style.display = "none";
    },
    // 53客服
    // handleClick() {
    // const mini_btn = document.getElementById('mini-btn')
    // if (mini_btn) {
    //   mini_btn.click()
    // }
    // var _53code = document.createElement("script");
    // _53code.src = "https://tb.53kf.com/code/code/e7947fd5e4009e40547964bebdb69f7b5/1";
    // _53code.setAttribute("async", true); // 可选，异步加载脚本
    // _53code.addEventListener("load", function () { });
    // var s = document.getElementsByTagName("script")[0];
    // s.parentNode.insertBefore(_53code, s);
    // }
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 60%;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  z-index: 9999999999999;

  .wechat,
  .customer {
    width: 5.81rem;
    height: 5.81rem;
    margin-bottom: 1.75rem;
    cursor: pointer;
  }

  .to-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #b4b4b4;
    font-size: 1.25rem;
    font-weight: 500;
    cursor: pointer;

    img {
      width: 1.94rem;
      height: 2rem;
    }
  }

  .bubble {
    width: 21.97rem;
    height: 20.56rem;
    position: absolute;
    right: 6.53rem;
    display: none;
    top: -1.5rem;
    background-size: 100% 100%;

    img {
      width: 16.56rem;
      height: 16.56rem;
      margin: 2rem 0 2rem -1rem;
    }
  }
}
</style>