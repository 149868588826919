<template>
  <div class="footer">
    <div class="footer-top">
      <div class="footer-top-first">
        <img class="logo" v-lazy="IMG_URL + 'logo-white.png'" alt="">
        <p>我们始终坚持着“为客户提供高质量、高效率软件解决
          方案”的核心价值观，不断超越客户的期望，赢得客户的信任和支持。提供稳定、可信赖的公务用车管理软件。</p>
        <button class="btn" @click="handleTry">免费使用</button>
      </div>
      <div class="footer-top-second">
        <span class="title">快速浏览</span>
        <ul>
          <li @click="handleClick(item.id)" v-for="item in linkList" :key="item.id">
            <img v-lazy="IMG_URL + 'right.png'" alt="">
            {{ item.label }}
          </li>
        </ul>
      </div>
      <div class="footer-top-third">
        <span class="title">联系我们</span>
        <ul>
          <li v-for="(item, index) in contractList" :key="index">
            <img v-lazy="IMG_URL + item.icon" alt="">
            {{ item.label }}
          </li>
        </ul>
      </div>
      <div class="footer-top-forth">
        <span class="title">关注我们</span>
        <img :src="ALL_IMG_URL + 'company-code.png'" alt="" class="QRcode">
      </div>
    </div>
    <div class="footer-bottom">
      合肥和行科技有限公司
      <img class="img" v-lazy="IMG_URL + 'footer.png'" alt="">
      皖公网安备 34019102000434号 皖ICP备18015830号-20
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      linkList: [
        { id: 0, label: '首页' },
        { id: 1, label: '产品功能' },
        { id: 5, label: '适用场景' },
        { id: 2, label: '智能硬件' },
        { id: 3, label: '服务版本' },
        { id: 4, label: '关于我们' },
      ],
      contractList: [{
        icon: 'icon1.png',
        label: 'service@jachx.com'
      }, {
        icon: 'icon2.png',
        label: '400-160-8899'
      }, {
        icon: 'icon3.png',
        label: '安徽省合肥市蜀山区紫蓬路1325号'
      }]
    }
  },
  methods: {
    // 底部快速浏览切换
    handleClick(id) {
      this.$emit('changeNavbarIdx', id)
      switch (id) {
        case 0:
          return this.$router.push('/');
        case 1:
          return this.$router.push('/product');
        case 2:
          return this.$router.push('/intelligent');
        case 3:
          return this.$router.push('/server');
        case 4:
          return this.$router.push('/about');
        case 5:
          return this.$router.push('/sence');
        default:
          return;
      }
    },
    // 注册试用
    handleTry() {
      _czc.push(["_trackEvent", "官网底部", "免费使用"])
      window.open('https://dip.igongwu.com/register')
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  background-color: #313A54;

  &-top {
    display: flex;
    justify-content: space-between;
    width: 75%;
    margin: 0 12.5%;
    text-align: left;
    padding: 5.38rem 0 4.44rem;

    &-first,
    &-second,
    &-third,
    &-forth {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .title {
        color: #ffffff;
        font-size: 1.25rem;
        font-weight: 500;
        margin-bottom: 1.81rem;
      }

      li {
        color: #ADB0BB;
        font-size: 1rem;
        font-weight: 400;
      }
    }

    &-first {
      width: 29.75rem;

      .logo {
        width: 5.88rem;
        height: 1.94rem;
      }

      p {
        color: #ADB0BB;
        font-size: 1rem;
        font-weight: 400;
        margin: 1.63rem 0 4.25rem;
        width: 100%;
      }

      .btn {
        width: 9.81rem;
        height: 2.94rem;
        border-radius: 0.19rem;
        background: #717EA7;
        color: #ffffff;
        font-size: 1.25rem;
        font-weight: 500;
        cursor: pointer;
      }

      .btn:hover {
        opacity: .8;
      }
    }

    &-second {
      li {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 1.5rem;

        img {
          width: 0.8rem;
          height: 0.8rem;
          margin-right: 0.82rem;
        }
      }

      li:hover {
        color: #fff;
      }
    }

    &-third {
      li {
        display: flex;
        align-items: center;
        margin-bottom: 1.27rem;

        img {
          width: 1.41rem;
          height: 1.41rem;
          margin-right: 0.82rem;
        }
      }
    }

    &-forth {
      .QRcode {
        width: 12.69rem;
        height: 12.69rem;
      }

    }
  }

  &-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.63rem;
    background-color: #202A45;
    color: #8A8B90;
    font-size: 1rem;
    font-weight: 400;
    line-height: 2.93rem;

    .img {
      width: 1.5rem;
      height: 1.5rem;
      margin: 0 0.8rem;
    }
  }
}
</style>