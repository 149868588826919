var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.MB_IMG_URL + 'add/logo1.png'),expression:"MB_IMG_URL + 'add/logo1.png'"}],staticClass:"logo",attrs:{"alt":""}}),(
      _vm.$store.state.activity &&
      (_vm.$store.state.activity.serverTag || _vm.$store.state.activity.deviceTag)
    )?_c('span',{staticClass:"header-tag"},[_vm._v(" 特惠 ")]):_vm._e(),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.MB_IMG_URL + 'choose.png'),expression:"MB_IMG_URL + 'choose.png'"}],staticClass:"choose",attrs:{"alt":""},on:{"click":function($event){_vm.maskFlag = true}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.maskFlag),expression:"maskFlag"}],staticClass:"mask"},[_c('ul',{staticClass:"animate__animated animate__fadeInRight"},_vm._l((_vm.navBarList),function(item){return _c('li',{key:item.id,on:{"click":function($event){return _vm.handleClick(item.id)}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.MB_IMG_URL + item.icon),expression:"MB_IMG_URL + item.icon"}],attrs:{"alt":""}}),_vm._v(" "+_vm._s(item.label)+" "),(
            (_vm.$store.state.activity &&
              item.id === 3 &&
              _vm.$store.state.activity.serverTag) ||
            (_vm.$store.state.activity &&
              item.id === 2 &&
              _vm.$store.state.activity.deviceTag)
          )?_c('span',{staticClass:"tag"},[_vm._v("特惠")]):_vm._e()])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }