var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header",attrs:{"id":"header"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.IMG_URL + 'logo.png'),expression:"IMG_URL + 'logo.png'"}],staticClass:"logo",attrs:{"alt":""}}),_c('ul',_vm._l((_vm.navbar),function(item){return _c('li',{key:item.id,class:_vm.navbarIdx == item.id ? 'active' : '',on:{"click":function($event){return _vm.handleClick(item.id)}}},[_vm._v(" "+_vm._s(item.label)+" "),(
          (_vm.$store.state.activity &&
            item.id === 3 &&
            _vm.$store.state.activity.serverTag) ||
          (_vm.$store.state.activity &&
            item.id === 2 &&
            _vm.$store.state.activity.deviceTag)
        )?_c('span',{staticClass:"tag"},[_vm._v("特惠")]):_vm._e()])}),0),_c('div',{staticClass:"btn"},[_c('button',{staticClass:"login",on:{"click":_vm.handleLogin}},[_vm._v("登录")]),_c('button',{staticClass:"try",on:{"click":_vm.handleTry}},[_vm._v("免费使用")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }