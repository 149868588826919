<template>
  <div id="app">
    <div class="pc" v-if="!isMobile()">
      <!-- 头部 -->
      <Header ref="header"></Header>
      <!-- 主体部分 -->
      <router-view />
      <!-- 底部 -->
      <Footer @changeNavbarIdx="changeNavbarIdx"></Footer>
      <!-- 悬浮组件 -->
      <Customer></Customer>
    </div>
    <div class="mb" v-else>
      <!-- 头部 -->
      <MbHeader v-if="showComponent"></MbHeader>
      <!-- 主体部分 -->
      <router-view />
      <!-- 底部 -->
      <MbFooter v-if="showComponent"></MbFooter>
      <!-- 底部按钮组件 -->
      <footerBtn v-if="showComponent"></footerBtn>
    </div>
  </div>
</template>

<script>
import Header from "../views/pc/components/headerName.vue";
import Footer from "../views/pc/components/footerName.vue";
import Customer from "../views/pc/components/customerName.vue";
import MbHeader from "../views/mb/components/headerName.vue";
import MbFooter from "../views/mb/components/footerName.vue";
import footerBtn from "@/views/mb/components/footerBtn.vue";
import { activity } from "@/utils/api";

export default {
  components: { Header, Footer, Customer, MbHeader, MbFooter, footerBtn },
  data() {
    return {
      showComponent: true,
    };
  },
  mounted() {
    // 根据不同路由跳转不同页面
    if (this.isMobile()) {
      this.$router.replace("/mb_index");
    } else {
      this.$router.replace("/");
    }
    this.getActivity();
  },
  // 根据路由判断是否展示头部、底部等组件
  updated() {
    if (this.$route.name === "MbRegister") {
      this.showComponent = false;
    } else {
      this.showComponent = true;
    }
  },
  methods: {
    // 判断是否是手机端，如果是，返回true
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    // 切换头部导航栏当前选中的项
    changeNavbarIdx(id) {
      this.$refs.header.navbarIdx = id;
    },
    // 获取当前生效中优惠活动
    getActivity() {
      activity().then((res) => {
        if (res.code === 0) {
          if (res.data) {
            res.data.scope.forEach((v) => {
              if (v === 1) {
                // 套餐服务活动
                res.data.serverTag = true;
              } else if (v === 2) {
                // 硬件设备活动
                res.data.deviceTag = true;
              }
            });
          }
          this.$store.commit("setActivity", res.data);
        }
      });
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Alibaba_PuHuiTi_2.0_55_Regular_55_Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // min-width: 960px;
}
</style>